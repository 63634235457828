import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { WithStyles } from '@material-ui/core/styles/withStyles';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import FitnessIcon from '@material-ui/icons/FitnessCenter';
import i18next from 'i18next';
import React from 'react';
import { BaseProductItem, Customer, Order } from '../models/Order';
import { getOrderCurrencySymbol } from '../utils/price';
import {
    shouldRenderCompany,
    getNoInvoiceText,
    getShopNoteRawState,
    getOrderBarcodeValue,
    calculateTotalOrderWeight
} from './OrderService';
import MUIRichTextEditor from 'mui-rte';
import Barcode from 'react-barcode';
import { OrderToShip } from 'src/shippingPlanning/ShippingPlanningService';
import { billingType, deliveryType } from 'src/common/enums/enums';
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';
import yellow from '@material-ui/core/colors/yellow';
import { ShortLabel } from 'src/common/print/ShortLabel';
import { Value } from 'src/common/print/Value';
import { LongLabel } from 'src/common/print/LongLabel';
import { getCountBackgroundColor } from './OrderPrintViewService';
import { isItemAnService } from './OrderServiceItemService';

interface Props extends WithStyles<typeof styles> {
    oversizedProductCodes?: string[];
    order: Order | OrderToShip;
    sequenceNumber?: number;
    deliveryTime?: string;
    marginTop?: number;
}

const styles = () =>
    createStyles({
        subheading: {
            marginTop: '20px'
        },
        supplier: {
            backgroundColor: yellow[200]
        },
        productItem: {},
        rightPadding: {
            paddingRight: '8px'
        }
    });

class OrderPrintViewBase extends React.Component<Props> {
    renderCompany(customer: Customer) {
        if (shouldRenderCompany(customer)) {
            return (
                <Grid container>
                    <Grid item container xs={8}>
                        <ShortLabel>{i18next.t('Company')}</ShortLabel>
                        <Value>{customer.company}</Value>
                    </Grid>
                    <Grid item xs={4}>
                        <ShortLabel>{i18next.t('Company ID')}</ShortLabel>
                        <Value>{customer.companyId}</Value>
                    </Grid>
                </Grid>
            );
        }
        return null;
    }

    renderCustomer(
        customerInfoName: string,
        customer: Customer,
        billingPhone?: string,
        delivery?: string
    ) {
        const { classes } = this.props;

        return (
            <>
                <Typography
                    className={classes.subheading}
                    variant="subtitle1"
                    gutterBottom
                >
                    {customerInfoName}
                    {delivery === deliveryType.PERSONAL && (
                        <>
                            <span> - </span>
                            <LongLabel highlight>
                                {i18next.t('orderPrint.personalDelivery')}
                            </LongLabel>
                        </>
                    )}
                </Typography>
                <Grid container>
                    <Grid item xs={12}>
                        <LongLabel>{i18next.t('Name')}</LongLabel>
                        <Value>{customer.name}</Value>
                    </Grid>
                    <Grid item xs={12}>
                        <LongLabel>{i18next.t('Phone')}</LongLabel>
                        <Value>{customer.phone}</Value>
                    </Grid>
                    {billingPhone !== customer.phone && (
                        <Grid item xs={12}>
                            <LongLabel>
                                {i18next.t('orderPrint.billingPhone')}
                            </LongLabel>
                            <Value>{billingPhone}</Value>
                        </Grid>
                    )}
                    {this.renderCompany(customer)}
                    <Grid item xs={12}>
                        <LongLabel>{i18next.t('Street')}</LongLabel>
                        <Value>{customer.street}</Value>
                    </Grid>
                    <Grid item xs={12}>
                        <LongLabel>{i18next.t('City')}</LongLabel>
                        <Value>{customer.city}</Value>
                    </Grid>
                    <Grid item xs={12}>
                        <LongLabel>{i18next.t('ZIP')}</LongLabel>
                        <Value>{customer.zip}</Value>
                    </Grid>
                </Grid>
            </>
        );
    }

    renderItems() {
        const { order, classes } = this.props;

        return (
            <>
                <Typography
                    className={classes.subheading}
                    variant="subtitle1"
                    gutterBottom
                >
                    {i18next.t('Items')}
                </Typography>
                <Table padding="none">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                {i18next.t('orderPrint.warehouse')}
                            </TableCell>
                            <TableCell>{i18next.t('Code')}</TableCell>
                            <TableCell>
                                {i18next.t('orderPrint.boxCount')}
                            </TableCell>
                            <TableCell />
                            <TableCell />
                            <TableCell style={{ width: '0.1%' }}>
                                {i18next.t('Quantity_Print')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {order.items.map(item => (
                            <>
                                {!item.subItems?.length &&
                                    this.renderItem(item, classes.productItem)}
                                {item.subItems?.map(subItem =>
                                    this.renderItem(
                                        subItem,
                                        classes.productItem
                                    )
                                )}
                            </>
                        ))}
                    </TableBody>
                </Table>
            </>
        );
    }

    renderItem = (item: BaseProductItem, className: string) => {
        const rightPaddingClass = this.props.classes.rightPadding;
        const isOversized = this.props.oversizedProductCodes?.includes(
            item.productCode
        );
        const isItemService = isItemAnService(item.productCode);
        return (
            <>
                <TableRow
                    key={item.productCode}
                    className={`${className} print-background`}
                >
                    <TableCell />
                    <TableCell
                        style={
                            isOversized
                                ? { backgroundColor: red[200] }
                                : undefined
                        }
                        className={rightPaddingClass}
                    >
                        <div style={{ display: 'flex' }}>
                            {isOversized && <FitnessIcon />}
                            <Value gutterBottom={false}>
                                <span className={this.props.classes.supplier}>
                                    {item.supplier ?? '-'}
                                </span>
                                {` # ${item.productCode}`}
                            </Value>
                        </div>
                    </TableCell>
                    <TableCell
                        size="small"
                        style={{ whiteSpace: 'nowrap' }}
                        className={rightPaddingClass}
                    >
                        {!isItemService && (
                            <>
                                <div>{`${item.boxCount ??
                                    '-'} (spolu ${item.boxCount *
                                    item.quantity})`}</div>
                                <div>{item.stockLocation}</div>
                            </>
                        )}
                    </TableCell>
                    <TableCell className={rightPaddingClass}>
                        <Value gutterBottom={false} variant="caption">
                            {item.name}
                        </Value>
                        {this.renderItemVariant(item)}
                    </TableCell>
                    <TableCell
                        style={{ width: '0.1%' }}
                        className={rightPaddingClass}
                    >
                        {!isItemService && (
                            <span>{(item.weight ?? 0) + 'kg'}</span>
                        )}
                    </TableCell>
                    <TableCell
                        style={{
                            backgroundColor: getCountBackgroundColor(
                                item.quantity
                            ),
                            width: '0.1%'
                        }}
                        className={rightPaddingClass}
                    >
                        <Value gutterBottom={false}>{item.quantity}</Value>
                    </TableCell>
                </TableRow>
            </>
        );
    };

    renderItemVariant(item: BaseProductItem) {
        if (item.variant) {
            return (
                <>
                    <br />
                    <Value gutterBottom={false}>{item.variant}</Value>
                </>
            );
        }
    }

    renderNotes() {
        const { order, classes } = this.props;
        if (!order.userNote && !order.shopNote) {
            return null;
        }

        return (
            <>
                <Typography
                    className={classes.subheading}
                    variant="subtitle1"
                    gutterBottom
                >
                    {i18next.t('Notes')}
                </Typography>
                <Grid container>
                    <Grid item xs={8}>
                        <ShortLabel>{i18next.t('Shop')}</ShortLabel>
                        <div className="print-background">
                            <MUIRichTextEditor
                                defaultValue={getShopNoteRawState(
                                    order.shopNote || i18next.t('None')
                                )}
                                readOnly
                                toolbar={false}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <ShortLabel>{i18next.t('Customer')}</ShortLabel>
                        <Value>{order.userNote || i18next.t('None')}</Value>
                    </Grid>
                </Grid>
            </>
        );
    }

    renderPaidValue() {
        const { order } = this.props;
        if (order.billing.type === billingType.QUATRO) {
            return (
                <div
                    className="print-background"
                    style={{
                        backgroundColor: blue[200]
                    }}
                >
                    <Value>Quatro</Value>
                </div>
            );
        }

        if (order.isPaid) {
            return (
                <>
                    <Value>{i18next.t('yes')}</Value>
                    <CheckIcon />
                </>
            );
        }

        return (
            <div
                className="print-background"
                style={{
                    backgroundColor: red[200]
                }}
            >
                <Value>{i18next.t('no')}</Value>
                <CloseIcon />
            </div>
        );
    }

    renderPayment() {
        const { order, classes } = this.props;

        const currency = getOrderCurrencySymbol(order);
        const formattedTotalPriceWithTax = `${currency} ${order.totalPriceWithTax}`;
        return (
            <>
                <Typography
                    className={classes.subheading}
                    variant="subtitle1"
                    gutterBottom
                >
                    {i18next.t('orderPrint.payment')}
                </Typography>
                <Grid container>
                    <Grid item container alignItems="center" xs={6}>
                        <ShortLabel>
                            {i18next.t('orderPrint.amount')}
                        </ShortLabel>
                        <Value>{formattedTotalPriceWithTax}</Value>
                    </Grid>
                    <Grid item container alignItems="center" xs={6}>
                        <ShortLabel>{i18next.t('Paid')}</ShortLabel>
                        {this.renderPaidValue()}
                    </Grid>
                </Grid>
            </>
        );
    }

    renderShippingInfo() {
        const { order } = this.props;

        return (
            <Grid container>
                <Grid item xs={12}>
                    {this.renderCustomer(
                        i18next.t('Shipping address'),
                        order.customer.delivery,
                        order.customer.billing.phone,
                        order.delivery.type
                    )}
                </Grid>
            </Grid>
        );
    }

    render() {
        const { order, sequenceNumber, deliveryTime } = this.props;

        if (!order) {
            return null;
        }

        const isOrderToShipView = !order.customer.billing.name;
        const sequencePrefix = sequenceNumber
            ? `${sequenceNumber}. `
            : undefined;

        return (
            <div className="print">
                <Grid
                    container
                    alignItems="center"
                    style={{ marginTop: this.props.marginTop }}
                >
                    <Grid item xs={7}>
                        <Typography variant="h4" gutterBottom>
                            {sequencePrefix}
                            {i18next.t('Order')} {order.orderNumber}
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Barcode
                            value={getOrderBarcodeValue(order.orderNumber)}
                            displayValue={false}
                            height={40}
                        />
                    </Grid>
                </Grid>
                <Typography gutterBottom color="textSecondary">
                    {i18next.t('Shop')} {order.shop.name}
                </Typography>
                <Grid container>
                    <Grid item container alignItems="center" xs={4}>
                        <ShortLabel>
                            {i18next.t('orderPrint.invoiceNumber')}
                        </ShortLabel>
                        <Value>
                            {getNoInvoiceText(order.invoice?.numberFormatted)}
                            {order.invoice?.numberFormatted}
                        </Value>
                    </Grid>
                    <Grid item container alignItems="center" xs={3}>
                        <ShortLabel>
                            {i18next.t('orderPrint.weight')}
                        </ShortLabel>
                        <Value>
                            {calculateTotalOrderWeight(order.items) + 'kg'}
                        </Value>
                    </Grid>
                    {deliveryTime && (
                        <Grid item container alignItems="center" xs={5}>
                            <LongLabel>
                                {i18next.t('orderPrint.deliveryTime')}
                            </LongLabel>
                            <Value>{deliveryTime}</Value>
                        </Grid>
                    )}
                </Grid>
                {this.renderShippingInfo()}
                {this.renderNotes()}
                {this.renderPayment()}
                {this.renderItems()}
                {isOrderToShipView && <div className="page-break-after" />}
            </div>
        );
    }
}

const OrderPrintView = withStyles(styles)(OrderPrintViewBase);

export { OrderPrintView };
