import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { StyleRules, withStyles, WithStyles } from '@material-ui/core/styles';
import i18next from 'i18next';
import { Moment } from 'moment';
import React, { ChangeEvent } from 'react';
import { JDatePicker } from 'src/components/JDatePicker';
import { OrderFlag } from 'src/models/Order';
import { Shipping } from 'src/shippingPlanning/ShippingPlanningService';
import { EnumsState } from '../common/enums/EnumsState';
import { Enum } from '../models/Enum';
import { OrderListQuery } from './OrderListState';
import { AdvancedUserRole } from 'src/components/AdvancedUserRole';

const decorate = withStyles(() => {
    const styles: StyleRules = {
        dialogRoot: {
            alignItems: 'flex-start'
        }
    };
    return styles;
});

interface Props extends WithStyles {
    open: boolean;
    enums: EnumsState;
    filterQuery: OrderListQuery;
    onClose: () => void;
    onFilterQueryUpdate: (event: ChangeEvent<HTMLInputElement>) => void;
    onFilterQueryPaymentStatusUpdate: (value?: boolean) => void;
    onFilterQueryQuatroUpdate: (value?: boolean) => void;
    onFilterQueryDateFromUpdate: (date: Moment) => void;
    onFilterQueryDateToUpdate: (date: Moment) => void;
    onFilterQuerySet: () => void;
    onFilterQueryReset: () => void;
}

const renderStateFilter = (
    enums: EnumsState,
    filterQuery: OrderListQuery,
    onFilterQueryUpdate
) => (
    <FormControl component="fieldset" margin="normal">
        <FormLabel component="legend">{i18next.t('State')}</FormLabel>
        <FormGroup row>
            {renderEnumOptions(
                enums.orderStatus.enum,
                'status',
                filterQuery,
                onFilterQueryUpdate
            )}
        </FormGroup>
    </FormControl>
);

const renderBillingTypeFilter = (
    enums: EnumsState,
    filterQuery: OrderListQuery,
    onFilterQueryUpdate
) => (
    <FormControl component="fieldset" margin="normal">
        <FormLabel component="legend">
            {i18next.t('orderFilter.billingType')}
        </FormLabel>
        <FormGroup row>
            {renderEnumOptions(
                enums.billingType.enum,
                'billingType',
                filterQuery,
                onFilterQueryUpdate
            )}
        </FormGroup>
    </FormControl>
);

const renderShippingFilter = (
    filterQuery: OrderListQuery,
    onFilterQueryUpdate
) => (
    <FormControl component="fieldset" margin="normal">
        <FormLabel component="legend">
            {i18next.t('orderFilter.deliveryType')}
        </FormLabel>
        <FormGroup row>
            {Object.values(Shipping).map(shipping => (
                <FormControlLabel
                    key={shipping}
                    control={
                        <Checkbox
                            onChange={onFilterQueryUpdate}
                            value={shipping}
                            checked={filterQuery.shipping?.includes(shipping)}
                            name="shipping"
                            color="primary"
                        />
                    }
                    label={i18next.t(`orderFilter.shipping__${shipping}`)}
                />
            ))}
        </FormGroup>
    </FormControl>
);

const renderCallStateFilter = (
    enums: EnumsState,
    filterQuery: OrderListQuery,
    onFilterQueryUpdate
) => (
    <FormControl component="fieldset" margin="normal">
        <FormLabel component="legend">{i18next.t('Call status')}</FormLabel>
        <FormGroup row>
            {renderEnumOptions(
                enums.orderCallStatus.enum,
                'callStatus',
                filterQuery,
                onFilterQueryUpdate
            )}
        </FormGroup>
    </FormControl>
);

const renderFlagsFilter = (
    filterQuery: OrderListQuery,
    onFilterQueryUpdate
) => (
    <FormControl fullWidth component="fieldset" margin="normal">
        <FormLabel component="legend">
            {i18next.t('orderFilter.flags')}
        </FormLabel>
        <FormGroup row>
            <FormControlLabel
                control={
                    <Checkbox
                        onChange={onFilterQueryUpdate}
                        value={OrderFlag.PriceChanged}
                        checked={filterQuery.flags?.includes(
                            OrderFlag.PriceChanged
                        )}
                        name="flags"
                        color="primary"
                    />
                }
                label={i18next.t('orderFilter.priceChangedFlag')}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        onChange={onFilterQueryUpdate}
                        value={OrderFlag.DoubleCheck}
                        checked={filterQuery.flags?.includes(
                            OrderFlag.DoubleCheck
                        )}
                        name="flags"
                        color="primary"
                    />
                }
                label={i18next.t('orderFilter.doubleCheckFlag')}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        onChange={onFilterQueryUpdate}
                        value={OrderFlag.ProductInfoMissing}
                        checked={filterQuery.flags?.includes(
                            OrderFlag.ProductInfoMissing
                        )}
                        name="flags"
                        color="primary"
                    />
                }
                label={i18next.t('orderFilter.productInfoMissingFlag')}
            />
        </FormGroup>
    </FormControl>
);

const renderPaymentStatusFilter = (
    filterQuery: OrderListQuery,
    onFilterQueryPaymentStatusUpdate
) => (
    <FormControl component="fieldset" margin="normal">
        <FormLabel component="legend">
            {i18next.t('orderFilter.paymentStatus')}
        </FormLabel>
        <FormGroup row>
            <FormControlLabel
                control={
                    <Checkbox
                        onChange={() => onFilterQueryPaymentStatusUpdate(true)}
                        checked={filterQuery.paymentStatus === true}
                        color="primary"
                    />
                }
                label={i18next.t('orderFilter.paid')}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        onChange={() => onFilterQueryPaymentStatusUpdate(false)}
                        checked={filterQuery.paymentStatus === false}
                        name="paymentStatus"
                        color="primary"
                    />
                }
                label={i18next.t('orderFilter.notPaid')}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        onChange={() =>
                            onFilterQueryPaymentStatusUpdate(undefined)
                        }
                        checked={filterQuery.paymentStatus === undefined}
                        name="paymentStatus"
                        color="primary"
                    />
                }
                label={i18next.t('orderFilter.paidAndNotPaid')}
            />
        </FormGroup>
    </FormControl>
);

const renderQuatroFilter = (
    filterQuery: OrderListQuery,
    onFilterQueryQuatroUpdate
) => (
    <FormControl component="fieldset" margin="normal">
        <FormLabel component="legend">
            {i18next.t('orderFilter.quatro')}
        </FormLabel>
        <FormGroup row>
            <FormControlLabel
                control={
                    <Checkbox
                        onChange={() => onFilterQueryQuatroUpdate(true)}
                        checked={filterQuery.isQuatroConfirmed === true}
                        color="primary"
                    />
                }
                label={i18next.t('orderFilter.quatroIsConfirmed')}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        onChange={() => onFilterQueryQuatroUpdate(false)}
                        checked={filterQuery.isQuatroConfirmed === false}
                        color="primary"
                    />
                }
                label={i18next.t('orderFilter.quatroIsNotConfirmed')}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        onChange={() => onFilterQueryQuatroUpdate(undefined)}
                        checked={filterQuery.isQuatroConfirmed === undefined}
                        color="primary"
                    />
                }
                label={i18next.t('orderFilter.quatroAll')}
            />
        </FormGroup>
    </FormControl>
);

const renderCountryFilter = (
    enums: EnumsState,
    filterQuery: OrderListQuery,
    onFilterQueryUpdate
) => (
    <AdvancedUserRole>
        <FormControl component="fieldset" margin="normal">
            <FormLabel component="legend">{i18next.t('Country')}</FormLabel>
            <FormGroup row>
                {renderEnumOptions(
                    enums.country.enum,
                    'country',
                    filterQuery,
                    onFilterQueryUpdate
                )}
            </FormGroup>
        </FormControl>
    </AdvancedUserRole>
);

const renderEnumOptions = (
    enumData: Enum,
    queryAttrName: string,
    filterQuery: OrderListQuery,
    onFilterQueryUpdate
) =>
    enumData.data.map(enumItem => (
        <FormControlLabel
            key={enumItem.code}
            control={
                <Checkbox
                    onChange={onFilterQueryUpdate}
                    checked={filterQuery[queryAttrName].includes(enumItem.code)}
                    value={enumItem.code}
                    name={queryAttrName}
                    color="primary"
                />
            }
            label={enumItem.label}
        />
    ));

const renderDateFilter = (
    filterQuery,
    onFilterQueryDateFromUpdate,
    onFilterQueryDateToUpdate
) => (
    <FormControl margin="normal">
        <FormLabel component="legend">{i18next.t('Date')}</FormLabel>
        <Grid container>
            <JDatePicker
                clearable
                disableFuture
                label={i18next.t('From')}
                value={filterQuery.dateFrom}
                onChange={onFilterQueryDateFromUpdate}
                style={{ marginRight: 10 }}
            />
            <JDatePicker
                clearable
                disableFuture
                label={i18next.t('To')}
                value={filterQuery.dateTo}
                onChange={onFilterQueryDateToUpdate}
            />
        </Grid>
    </FormControl>
);

const OrderListFilterDialog = decorate(
    ({
        enums,
        filterQuery,
        open,
        onClose,
        onFilterQueryUpdate,
        onFilterQueryDateFromUpdate,
        onFilterQueryDateToUpdate,
        onFilterQuerySet,
        onFilterQueryReset,
        onFilterQueryPaymentStatusUpdate,
        onFilterQueryQuatroUpdate,
        classes
    }: Props) => (
        <Dialog
            open={open}
            onClose={onClose}
            classes={{ root: classes.dialogRoot }}
            disableEscapeKeyDown
        >
            <DialogTitle>{i18next.t('Orders filter')}</DialogTitle>
            <DialogContent>
                {renderStateFilter(enums, filterQuery, onFilterQueryUpdate)}
                {renderFlagsFilter(filterQuery, onFilterQueryUpdate)}
                {renderBillingTypeFilter(
                    enums,
                    filterQuery,
                    onFilterQueryUpdate
                )}
                {renderShippingFilter(filterQuery, onFilterQueryUpdate)}
                {renderPaymentStatusFilter(
                    filterQuery,
                    onFilterQueryPaymentStatusUpdate
                )}
                {renderQuatroFilter(filterQuery, onFilterQueryQuatroUpdate)}
                {renderCallStateFilter(enums, filterQuery, onFilterQueryUpdate)}
                {renderCountryFilter(enums, filterQuery, onFilterQueryUpdate)}
                {renderDateFilter(
                    filterQuery,
                    onFilterQueryDateFromUpdate,
                    onFilterQueryDateToUpdate
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    color="secondary"
                    variant="contained"
                    onClick={onFilterQueryReset}
                >
                    {i18next.t('Clear')}
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={onFilterQuerySet}
                >
                    {i18next.t('Apply')}
                </Button>
            </DialogActions>
        </Dialog>
    )
);

export { OrderListFilterDialog };
