import { Moment } from 'moment';
import { Shipping } from 'src/shippingPlanning/ShippingPlanningService';
import { onlyUnique } from 'src/common/UtilsService';

export interface CourierOverview {
    shipping: Shipping.COURIER_Z | Shipping.COURIER_H;
    date: string;
    count: number;
}

interface OrderCounts {
    zemianskaCount: number;
    hamrickyCount: number;
}

interface CourierOverviewGraph extends OrderCounts {
    date: string;
}

export interface OrderExpeditionInfo {
    orderNumber: string;
    firstCartageName: string;
    shippingTimeInDays: string;
    country: 'SK' | 'HU';
}

export interface ExpeditionInfoPerDaySum {
    expeditionDays: number;
    countOfOrders: {
        SK: number;
        HU: number;
    };
    orderNumbers: {
        SK: string[];
        HU: string[];
    };
}

export const formatQueryDate = (date: Moment) =>
    date ? date.format('YYYY-MM-DD') : undefined;

type ExpeditionInfoPerDaySumRecord = Record<
    string,
    {
        count: {
            SK: number;
            HU: number;
        };
        orderNumbers: {
            SK: string[];
            HU: string[];
        };
    }
>;

const DEFAULT_COUNT_OF_ORDER = { SK: 0, HU: 0 };
const DEFAULT_ORDER_NUMBERS = { SK: [], HU: [] };

const supportedCountries = ['SK', 'HU'];

export const getExpeditionInfoPerDaySum = (
    info: OrderExpeditionInfo[]
): ExpeditionInfoPerDaySum[] => {
    const infoSum: ExpeditionInfoPerDaySumRecord = info.reduce(
        (sum, { shippingTimeInDays: expeditionDays, orderNumber, country }) => {
            if (!supportedCountries.includes(country)) {
                return sum;
            }

            const count = sum[expeditionDays]?.count ?? DEFAULT_COUNT_OF_ORDER;
            const orderNumbers =
                sum[expeditionDays]?.orderNumbers ?? DEFAULT_ORDER_NUMBERS;
            return {
                ...sum,
                [expeditionDays]: {
                    count: {
                        ...count,
                        [country]: count[country] + 1
                    },
                    orderNumbers: {
                        ...orderNumbers,
                        [country]: [...orderNumbers[country], orderNumber]
                    }
                }
            };
        },
        {} as ExpeditionInfoPerDaySumRecord
    );

    return Object.entries(infoSum).map(
        ([expeditionDays, { count, orderNumbers }]) => ({
            expeditionDays: Number(expeditionDays),
            countOfOrders: count,
            orderNumbers: orderNumbers
        })
    );
};

export const getUniqueCartageNames = (
    info: OrderExpeditionInfo[]
): string[] => {
    return info
        .map(({ firstCartageName }) => firstCartageName)
        .filter(onlyUnique);
};
