import get from 'lodash.get';
import { convertFromHTML, ContentState, convertToRaw } from 'draft-js';
import {
    country,
    discountType,
    orderStatus,
    productStatus
} from '../common/enums/enums.js';
import { Enum } from '../models/Enum';
import { Customer, Invoice, Order, ProductItem } from '../models/Order';
import i18next from 'i18next';
import { orderBarcodeValuePrefix } from 'src/common/scannerService';
import { isProductionEnv } from 'src/common/EnvironmentService';
import { roundToTwoDecimalPlaces } from 'src/utils/price';

export function removeItemFromArray(
    item: ProductItem,
    items: ProductItem[]
): ProductItem[] {
    const itemIndex = items.indexOf(item);
    if (itemIndex > -1) {
        const newArr = [...items];
        newArr.splice(itemIndex, 1);
        return newArr;
    }
    return items;
}

const ZERO_DISCOUNT = 0;

const orderStatusToProductStatus = {
    [orderStatus.RECEIVED]: [
        productStatus.NONE,
        productStatus.STOCKED,
        productStatus.NOT_STOCKED
    ],
    [orderStatus.TO_ORDER]: [
        productStatus.NOT_STOCKED,
        productStatus.ORDERED,
        productStatus.STOCKED
    ],
    [orderStatus.ORDERED]: [
        productStatus.ORDERED,
        productStatus.STOCKED,
        productStatus.NOT_STOCKED
    ],
    [orderStatus.PROCESSED]: [productStatus.STOCKED, productStatus.NOT_STOCKED],
    [orderStatus.READY_TO_SHIP]: [
        productStatus.STOCKED,
        productStatus.DONE,
        productStatus.NOT_STOCKED,
        productStatus.READY_TO_SHIP
    ],
    [orderStatus.DONE]: [
        productStatus.DONE,
        productStatus.STOCKED,
        productStatus.NOT_STOCKED,
        productStatus.RETURNED
    ],
    [orderStatus.CANCELED]: Object.values(productStatus)
};

export function filterValidProductStatuses(
    productStatusEnum: Enum,
    orderStatus: string
): Enum {
    const filteredEnumItems = productStatusEnum.data.filter(enumItem =>
        (orderStatusToProductStatus[orderStatus] || []).includes(enumItem.code)
    );
    return {
        ...productStatusEnum,
        data: filteredEnumItems
    };
}

const skInvoiceLang = 'slo';
const countryInvoiceLangMap = {
    [country.SK]: skInvoiceLang,
    [country.HU]: 'hun',
    [country.CZ]: 'cze',
    [country.RO]: 'rom',
    [country.HR]: 'hrv',
    [country.SI]: 'slv'
};

function getSuperFakturyUrl(): string {
    if (isProductionEnv()) {
        return 'https://moja.superfaktura.sk';
    }

    return 'https://sandbox.superfaktura.sk';
}

export function getInvoiceUrl({ shop, invoice: { id, token } }: Order): string {
    const lang = countryInvoiceLangMap[shop.country] || skInvoiceLang;
    return `${getSuperFakturyUrl()}/${lang}/invoices/pdf/${id}/token:${token}`;
}

export function getInvoiceLink(invoiceId: string): string {
    return `${getSuperFakturyUrl()}/invoices/view/${invoiceId}`;
}

export function getPrice(value: number, percentageDiscount?: number): number {
    const price = Number(value);
    const priceWithoutDiscount = isNaN(price) ? 0 : price;
    if (percentageDiscount === ZERO_DISCOUNT) {
        return priceWithoutDiscount;
    }

    return (
        Math.round(
            priceWithoutDiscount * 100 * ((100 - percentageDiscount) / 100)
        ) / 100
    );
}

export function applyDiscountNominal(
    price,
    discountTypeValue,
    discount = ZERO_DISCOUNT
) {
    if (discountTypeValue === discountType.NOMINAL) {
        return price - discount;
    }

    return price;
}

export function getPercentageDiscount(order: Order): number {
    if (order.discountType === discountType.PERCENTAGE) {
        return order.discount || ZERO_DISCOUNT;
    }

    return ZERO_DISCOUNT;
}

export function calculateTotalOrderWeight(items: ProductItem[]): number {
    return Number(
        items
            .reduce((totalOrderWeight, item) => {
                const itemTotalWeight = (item.weight ?? 0) * item.quantity;
                return totalOrderWeight + itemTotalWeight;
            }, 0)
            .toFixed(2)
    );
}

const getPriceSelector = (
    withoutTax: boolean
): 'unitPriceWithTax' | 'unitPrice' => {
    if (withoutTax) {
        return 'unitPrice';
    }

    return 'unitPriceWithTax';
};

export function calculateTotalPrice(order: Order, withoutTax = false) {
    const priceSelector = getPriceSelector(withoutTax);

    const productsPrice = order.items.reduce((sum, item) => {
        const itemPrice = getPrice(
            item[priceSelector],
            getPercentageDiscount(order)
        );
        return sum + itemPrice * item.quantity;
    }, 0);
    const billingPrice = getPrice(order.billing[priceSelector], ZERO_DISCOUNT);
    const deliveryPrice = getPrice(
        order.delivery[priceSelector],
        ZERO_DISCOUNT
    );

    const totalPrice = productsPrice + billingPrice + deliveryPrice;
    const totalPriceAfterDiscountWithTax = applyDiscountNominal(
        totalPrice,
        order.discountType,
        order.discount
    );

    return roundToTwoDecimalPlaces(totalPriceAfterDiscountWithTax);
}

export function isOrderCanceled(order): boolean {
    return order.status === orderStatus.CANCELED;
}

export function isOrderProcessed(order): boolean {
    return order.status === orderStatus.PROCESSED;
}

export function isOrderReceived(order): boolean {
    return order.status === orderStatus.RECEIVED;
}

export function isOrderDone(order): boolean {
    return order.status === orderStatus.DONE;
}

export function isOrderReadyToShip(order): boolean {
    return order.status === orderStatus.READY_TO_SHIP;
}

const alreadyProcessedStatuses = [
    orderStatus.PROCESSED,
    orderStatus.READY_TO_SHIP,
    orderStatus.DONE,
    orderStatus.CANCELED
];

export function isOrderAlreadyProcessed(order: Order): boolean {
    return alreadyProcessedStatuses.includes(order.status);
}

export function isOrderCanceledOrDone(order: Order): boolean {
    return isOrderCanceled(order) || isOrderDone(order);
}

export function isOrderReadyToShipOrCanceledOrDone(order: Order): boolean {
    return (
        isOrderReadyToShip(order) ||
        isOrderCanceled(order) ||
        isOrderDone(order)
    );
}

export function shouldRenderCompany(customer: Customer) {
    return customer.company || customer.companyId || customer.vatId;
}

export interface InvoiceActionSuccessResponse {
    _id: string;
    invoice: Invoice;
    version: number;
}

export function isLastArrayItem(elIndex: number, arrayLength: number) {
    return elIndex + 1 === arrayLength;
}

export function isOrderWithoutInvoice(order: Order): boolean {
    return !get(order, 'invoice.id');
}

export function getShopOrderDetailLink(order: Order): string | undefined {
    if (!order.shop.baseUrl) {
        return;
    }

    return `${order.shop.baseUrl}/admin/objednavky-detail/?id=${order.orderId}`;
}

export function updateOrderAfterInvoiceAction(
    order: Order,
    actionResponse: InvoiceActionSuccessResponse
): Order {
    if (order._id !== actionResponse._id) {
        return order;
    }

    return {
        ...order,
        ...actionResponse
    };
}

export function getNoInvoiceText(invoiceFormattedNumber?: string): string {
    if (invoiceFormattedNumber) {
        return '';
    }

    return i18next.t('order.invoiceFormattedNumberDoesNotExist');
}

export function getShopNoteRawState(
    shopNote: string | null | undefined
): string {
    const note = shopNote ?? '';

    if (note?.startsWith('{"blocks":')) {
        return shopNote;
    }

    const contentHTML = convertFromHTML(note);
    const state = ContentState.createFromBlockArray(
        contentHTML.contentBlocks,
        contentHTML.entityMap
    );
    return JSON.stringify(convertToRaw(state));
}

export function getOrderBarcodeValue(orderNumber: string): string {
    return orderBarcodeValuePrefix + orderNumber;
}

export function getOrderTitle(isClaim: boolean, isClone: boolean): string {
    if (isClaim) {
        return i18next.t('order.claimTitle');
    }

    if (isClone) {
        return i18next.t('order.cloneTitle');
    }

    return i18next.t('Order');
}
