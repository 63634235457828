import debounce from 'lodash.debounce';
import { Moment } from 'moment';
import React from 'react';
import { ChangeEvent, Component, MouseEvent } from 'react';
import { connect } from 'react-redux';
import { orderStatus } from '../common/enums/enums';
import { EnumsState } from '../common/enums/EnumsState';
import { AppState } from '../models/AppState';
import { Order } from '../models/Order';
import {
    loadOrders,
    resetOrders,
    updateOrdersStatus,
    updateQuery
} from './OrderListActions';
import { OrderListAppBarView } from './OrderListAppBarView';
import {
    filterCheckedOrders,
    filterOrders,
    getOrdersIds
} from './OrderListService';
import { OrderListQuery } from './OrderListState';
import { SEARCH_DELAY } from '../config';
import { toggleArrayItem } from 'src/common/UtilsService';
import { isSimpleUser } from 'src/common/session/SessionService';
import { SessionState } from 'src/common/session/SessionState';

const defaultFilterDialogQuery: OrderListQuery = {
    status: [],
    callStatus: [],
    billingType: [],
    shipping: [],
    country: [],
    dateFrom: null,
    dateTo: null,
    paymentStatus: undefined,
    flags: [],
    isQuatroConfirmed: undefined
};

interface Props {
    query: OrderListQuery;
    enums: EnumsState;
    checkedOrders: string[];
    orders: Order[];
    isLoading: boolean;
    session: SessionState;
    onLoadOrders: (query?: OrderListQuery) => void;
    onUpdateQuery: (query: OrderListQuery) => void;
    onResetOrders: () => void;
    onUpdateOrdersStatus: (orderIds: string[], status: string) => void;
}

interface State {
    isFilterDialogOpen: boolean;
    filterDialogQuery: OrderListQuery;
}

export class OrderListAppBarContainerBase extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state.filterDialogQuery = {
            ...defaultFilterDialogQuery,
            ...props.query
        };

        this.loadOrdersOnSearchChange = debounce(
            this.loadOrdersOnSearchChange,
            SEARCH_DELAY
        );
    }

    state = {
        isFilterDialogOpen: false,
        filterDialogQuery: { ...defaultFilterDialogQuery }
    };

    render() {
        const {
            query,
            enums,
            orders,
            isLoading,
            checkedOrders,
            session
        } = this.props;
        return (
            <OrderListAppBarView
                enums={enums}
                query={query}
                orders={orders}
                isLoading={isLoading}
                checkedOrders={checkedOrders}
                filterQuery={this.state.filterDialogQuery}
                isFilterDialogOpen={this.state.isFilterDialogOpen}
                onFilterDialogClose={this.onFilterDialogClose}
                onSearchChange={this.onSearchChange}
                onFilterIconClick={this.onFilterIconClick}
                onFilterQueryUpdate={this.onFilterQueryUpdate}
                onFilterQuerySet={this.onFilterQuerySet}
                onFilterQueryReset={this.onFilterQueryReset}
                onFilterQueryDateFromUpdate={this.onFilterQueryDateFromUpdate}
                onFilterQueryDateToUpdate={this.onFilterQueryDateToUpdate}
                onFilterQueryPaymentStatusUpdate={
                    this.onFilterQueryPaymentStatusUpdate
                }
                onFilterQueryQuatroUpdate={this.onFilterQueryQuatroUpdate}
                onChangeOrdersStatusToDelivered={
                    this.onChangeOrdersStatusToDelivered
                }
                onRefreshOrders={this.onRefreshOrders}
                isSimpleUser={isSimpleUser(session)}
            />
        );
    }

    onSearchChange = async (value: string) => {
        await this.props.onUpdateQuery({
            search: value,
            skip: 0
        });
        this.loadOrdersOnSearchChange();
    };

    onFilterIconClick = (event: MouseEvent<HTMLInputElement>) => {
        event.preventDefault();
        this.setState({ isFilterDialogOpen: true });
    };

    onFilterDialogClose = () => {
        this.setState({ isFilterDialogOpen: false });
    };

    onFilterQueryUpdate = (event: ChangeEvent<HTMLInputElement>) => {
        const attrName = event.currentTarget.name;
        const value = event.currentTarget.value;

        this.setState({
            filterDialogQuery: {
                ...this.state.filterDialogQuery,
                [attrName]: toggleArrayItem(
                    this.state.filterDialogQuery[attrName],
                    value
                )
            }
        });
    };

    onFilterQueryPaymentStatusUpdate = (value?: boolean) => {
        this.setState({
            filterDialogQuery: {
                ...this.state.filterDialogQuery,
                paymentStatus: value
            }
        });
    };

    onFilterQueryQuatroUpdate = (value?: boolean) => {
        this.setState({
            filterDialogQuery: {
                ...this.state.filterDialogQuery,
                isQuatroConfirmed: value
            }
        });
    };

    onFilterQueryDateFromUpdate = (date: Moment) => {
        this.updateFilterQueryDate(date, 'dateFrom');
    };

    onFilterQueryDateToUpdate = (date: Moment) => {
        this.updateFilterQueryDate(date, 'dateTo');
    };

    updateFilterQueryDate = (date: Moment, attr: string) => {
        this.setState({
            filterDialogQuery: {
                ...this.state.filterDialogQuery,
                [attr]: date
            }
        });
    };

    onFilterQuerySet = () => {
        this.setState({ isFilterDialogOpen: false });
        this.updateQueryAndLoadOrders(this.state.filterDialogQuery);
    };

    onFilterQueryReset = () => {
        this.setState({
            isFilterDialogOpen: false,
            filterDialogQuery: { ...defaultFilterDialogQuery }
        });
        this.updateQueryAndLoadOrders(defaultFilterDialogQuery);
    };

    loadOrdersOnSearchChange = async () => {
        await this.props.onResetOrders();
        this.props.onLoadOrders(this.props.query);
    };

    onRefreshOrders = async () => {
        await this.props.onResetOrders();
        this.props.onLoadOrders(this.props.query);
    };

    async updateQueryAndLoadOrders(query: OrderListQuery) {
        await this.props.onUpdateQuery({
            ...query,
            search: this.props.query.search
        });
        this.loadOrdersOnSearchChange();
    }

    onChangeOrdersStatusToDelivered = () => {
        const { orders, checkedOrders, onUpdateOrdersStatus } = this.props;
        const readyToShipOrders = filterOrders(
            orders,
            orderStatus.READY_TO_SHIP
        );
        const checkedReadyToShipOrders = filterCheckedOrders(
            readyToShipOrders,
            checkedOrders
        );
        const checkedReadyToShipOrdersIds = getOrdersIds(
            checkedReadyToShipOrders
        );
        onUpdateOrdersStatus(checkedReadyToShipOrdersIds, orderStatus.DONE);
    };
}

const mapStateToProps = (state: AppState) => ({
    query: state.orderList.query,
    enums: state.enums,
    checkedOrders: state.orderList.checkedOrders,
    orders: state.orderList.data,
    isLoading: state.orderList.isLoading,
    session: state.session
});

const mapDispatchToProps = {
    onLoadOrders: loadOrders,
    onUpdateQuery: updateQuery,
    onResetOrders: resetOrders,
    onUpdateOrdersStatus: updateOrdersStatus
};

const OrderListAppBarContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderListAppBarContainerBase);

export { OrderListAppBarContainer };
