import { Action, handleActions } from 'redux-actions';
import { Order, ProductItem } from '../models/Order';
import * as actions from './OrderActions';
import {
    InvoiceActionSuccessResponse,
    removeItemFromArray,
    updateOrderAfterInvoiceAction
} from './OrderService';
import { OrderState } from './OrderState';
import { setValue } from 'src/utils/object';
import { getCountryVat } from 'src/utils/price';

const defaultState: OrderState = {
    data: null,
    isEditing: false,
    isLoading: false,
    isSaving: false,
    isExportingInvoice: false,
    isDeletingInvoice: false
};

const productNoneStatus = 'NONE';

const newItem: ProductItem = {
    productCode: '',
    supplier: '',
    name: '',
    quantity: 1,
    status: productNoneStatus,
    unitPrice: undefined,
    unitPriceWithTax: undefined,
    weight: 0,
    vatRate: undefined
};

const getNewItemWithVat = (country: string): ProductItem => ({
    ...newItem,
    vatRate: getCountryVat(country)
});

interface RemoveItemPayload {
    item: ProductItem;
}

interface AddSubItemPayload {
    item: ProductItem;
}

interface RemoveSubItemPayload {
    item: ProductItem;
    subItem: ProductItem;
}

interface ReceiveOrderPayload {
    order: Order;
}

interface UpdateOrderPayload {
    propName: string;
    propValue: string;
}

interface ExportInvoiceSuccessPayload {
    order: InvoiceActionSuccessResponse;
}

export const OrderReducer = handleActions<OrderState, any>(
    {
        [actions.loadingOrder.toString()]: () => ({
            ...defaultState,
            isLoading: true,
            data: null
        }),
        [actions.invalidateOrderCache.toString()]: () => ({
            ...defaultState
        }),
        [actions.loadOrderSuccess.toString()]: (
            state: OrderState,
            action: Action<ReceiveOrderPayload>
        ) => ({
            ...state,
            data: action.payload.order,
            isEditing: false,
            isLoading: false
        }),
        [actions.loadOrderFailure.toString()]: (state: OrderState) => ({
            ...state,
            isLoading: false
        }),
        [actions.removeItem.toString()]: (
            state: OrderState,
            action: Action<RemoveItemPayload>
        ) => ({
            ...state,
            isEditing: true,
            data: removeItem(state.data, action.payload.item)
        }),
        [actions.addItem.toString()]: (state: OrderState) => ({
            ...state,
            isEditing: true,
            data: {
                ...state.data,
                items: [
                    ...state.data.items,
                    getNewItemWithVat(state.data.shop.country)
                ]
            }
        }),
        [actions.addSubItem.toString()]: (
            state: OrderState,
            action: Action<AddSubItemPayload>
        ) => ({
            ...state,
            isEditing: true,
            data: {
                ...state.data,
                items: state.data.items.map(item => {
                    if (item !== action.payload.item) {
                        return item;
                    }

                    return {
                        ...item,
                        subItems: [{ ...newItem }, ...(item.subItems ?? [])]
                    };
                })
            }
        }),
        [actions.removeSubItem.toString()]: (
            state: OrderState,
            action: Action<RemoveSubItemPayload>
        ) => ({
            ...state,
            isEditing: true,
            data: {
                ...state.data,
                items: state.data.items.map(item => {
                    if (item !== action.payload.item) {
                        return item;
                    }

                    return {
                        ...item,
                        subItems: removeItemFromArray(
                            action.payload.subItem,
                            item.subItems ?? []
                        )
                    };
                })
            }
        }),
        [actions.updateOrder.toString()]: (
            state: OrderState,
            action: Action<UpdateOrderPayload>
        ) => ({
            ...state,
            isEditing: true,
            data: setValue(
                state.data,
                action.payload.propName,
                action.payload.propValue
            )
        }),
        [actions.exportingInvoice.toString()]: (state: OrderState) => ({
            ...state,
            isExportingInvoice: true
        }),
        [actions.exportInvoiceSuccess.toString()]: (
            state: OrderState,
            action: Action<ExportInvoiceSuccessPayload>
        ) => ({
            ...state,
            data: updateOrderAfterInvoiceAction(
                state.data,
                action.payload.order
            ),
            isExportingInvoice: false
        }),
        [actions.exportInvoiceFailure.toString()]: (state: OrderState) => ({
            ...state,
            isExportingInvoice: false
        }),
        [actions.savingOrder.toString()]: (state: OrderState) => ({
            ...state,
            isSaving: true
        }),
        [actions.saveOrderSuccess.toString()]: (state: OrderState) => ({
            ...state,
            isEditing: false,
            isSaving: false
        }),
        [actions.saveOrderFailure.toString()]: (state: OrderState) => ({
            ...state,
            isSaving: false
        }),
        [actions.deletingInvoice.toString()]: (state: OrderState) => ({
            ...state,
            isDeletingInvoice: true
        }),
        [actions.deleteInvoiceSuccess.toString()]: (
            state: OrderState,
            action: Action<ExportInvoiceSuccessPayload>
        ) => ({
            ...state,
            data: updateOrderAfterInvoiceAction(
                state.data,
                action.payload.order
            ),
            isDeletingInvoice: false
        }),
        [actions.deleteInvoiceFailure.toString()]: (state: OrderState) => ({
            ...state,
            isDeletingInvoice: false
        })
    },
    defaultState
);

function removeItem(order: Order, item: ProductItem): Order {
    return {
        ...order,
        items: removeItemFromArray(item, order.items)
    };
}
